import React from 'react';
import ReactDOM from 'react-dom/client';
import { StyleSheetManager } from 'styled-components';
import { ChatWidget } from './components/Chat/index';
import './index.css';

let widgetKey = '';

class ChatWidgetElement extends HTMLElement {
  connectedCallback() {
    const shadowRoot = this.attachShadow({ mode: 'open' });
    const styleNode = document.createElement('style');
    const rootElement = document.createElement('div');
    shadowRoot.appendChild(styleNode);
    shadowRoot.appendChild(rootElement);

    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <StyleSheetManager target={styleNode}>
          <ChatWidget widgetKey={widgetKey} />
        </StyleSheetManager>
      </React.StrictMode>,
    );
  }
}

customElements.define('chat-widget', ChatWidgetElement);

window.ChatWidget = {
  load: function ({ id }) {
    widgetKey = id;
    const chatWidgetElement = document.createElement('chat-widget');
    document.body.appendChild(chatWidgetElement);
  },
};

//Uncomment for development
// const root = ReactDOM.createRoot(document.getElementById('test_container'));
// root.render(<ChatWidget widgetKey="Xnl0yowwNLfJ4XW+pPbe/Q==" />);
