import styled, { css, keyframes } from 'styled-components';

const t = keyframes`
  from {
    background-size: 0 200%;
  }
  `;
const b = keyframes`
  50% {
    background-position: 0 -100%, 0 0;
  }
  `;

const fadeInLeft = keyframes`
0% {
  opacity: 0;
  transform: translateX(-40px);
}
100% {
  opacity: 1;
  transform: translateX(0);
}
  `;

const fadeInRight = keyframes`
0% {
  opacity: 0;
  transform: translateX(40px);
}
100% {
  opacity: 1;
  transform: translateX(0);
}
  `;

export const StyledMessageItemBlock = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding-right: 4px;
  ${({ $isUserMessage }) =>
    $isUserMessage
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: flex-start;
        `}
`;

export const StyledUserMessageItem = styled.div`
  font-family: 'Roboto', sans-serif;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  font-size: 13px;
  font-weight: 400;
  color: white;
  line-height: 16px;
  padding: 16px;
  min-width: 60px;
  max-width: 80%;
  word-break: break-all;
  background-color: ${({ $primaryColor }) =>
    $primaryColor ? $primaryColor : '#00b1e5'};
  border-radius: 20px 0px 20px 20px;
  margin-right: 8px;
  transition: all 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-name: ${fadeInRight};
  -webkit-animation-name: ${fadeInRight};
  svg {
    fill: white;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    &:hover {
      fill: red;
    }
  }
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    display: block;
    border-left: ${({ $primaryColor }) =>
      $primaryColor ? `5px solid ${$primaryColor}` : '5px solid #00b1e5'};
    border-right: 5px solid transparent;
    border-top: ${({ $primaryColor }) =>
      $primaryColor ? `5px solid ${$primaryColor}` : '5px solid #00b1e5'};
    border-bottom: 5px solid transparent;
    position: absolute;
    transition: all 0.3s;
    top: 0px;
    right: -9px;
  }
  ${({ $isPredefinedQuestion }) =>
    $isPredefinedQuestion &&
    css`
      border: 1px solid;
      border-color: ${({ $primaryColor }) =>
        $primaryColor ? $primaryColor : '#00b1e5'};
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      border-radius: 20px;
      padding: 6px 12px;
      &:before {
        content: '';
        border: none;
      }
      &:hover {
        background-color: white;
        color: ${({ $primaryColor }) =>
          $primaryColor ? $primaryColor : '#00b1e5'};
        & svg {
          fill: ${({ $primaryColor }) =>
            $primaryColor ? $primaryColor : '#00b1e5'};
        }
      }
    `}
`;

export const StyledAIMessageItem = styled.div`
  font-family: 'Roboto', sans-serif;
  position: relative;
  box-sizing: border-box;
  background: grey;
  border-radius: 0px 20px 20px 20px;
  max-width: 80%;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding: 16px;
  margin-left: 8px;
  transition: all 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-name: ${fadeInLeft};
  -webkit-animation-name: ${fadeInLeft};
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid grey;
    border-top: 5px solid grey;
    border-bottom: 5px solid transparent;
    position: absolute;
    top: 0px;
    left: -8px;
  }
`;

export const StyledMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  line-height: 16px;
  word-break: break-all;
  font-size: 13px;
  font-weight: 400;
`;

export const StyledUserMessageSource = styled.div`
  font-family: 'Roboto', sans-serif;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  word-break: break-all;
`;

export const StyledAiMessageSource = styled.div`
  font-family: 'Roboto', sans-serif;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  word-break: break-all;
`;

export const StyledAITypingMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  color: #0000;
  background:
    linear-gradient(-90deg, white 5px, #0000 0) 10px 0,
    linear-gradient(white 0 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation:
    ${b} 1s infinite steps(1),
    ${t} calc(var(--n) * 0.02s) steps(var(--n)) forwards;
`;
