import styled, { css, keyframes } from 'styled-components';

const typingMessage = keyframes`
  0% {
    background-color: #606060;
  }
  50% {
    background-color: #fff;
  }
  60% {
    background-color: #606060;
  }
  `;

const fadeInUp = keyframes`
  from {
    transform: translate3d(0,505px,0)
  }
  to{
    transform: translate3d(0,0,0);
       opacity: 1
  }
  `;

const fadeInDown = keyframes`
  from {
    transform: translate3d(0,0,0);
       opacity: 1
  }
  to{
    transform: translate3d(0,505px,0)
  }
  `;

export const StyledChatWidget2Window = styled.div`
  width: 45vw;
  height: 500px;
  border-radius: 24px;
  visibility: hidden;
  z-index: 9999;
  ${({ $isChatWindowOpen }) =>
    $isChatWindowOpen
      ? css`
  visibility: visible;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both
  opacity: 0
  animation-name: ${fadeInUp};
  -webkit-animation-name: ${fadeInUp};
    `
      : css`
  visibility: ${({ $isFirstRender }) =>
    $isFirstRender ? 'hidden' : 'visible'};
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both
  opacity: 0
  animation-name: ${fadeInDown};
  -webkit-animation-name: ${fadeInDown};
    `}
  ${({ $primaryColor }) =>
    $primaryColor &&
    css`
      background: linear-gradient(
        45deg,
        ${$primaryColor + '20'} 0%,
        ${$primaryColor + '20'} 100%
      );
    `}
  transition: all 0.6s;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  @media (max-width: 950px) {
    width: 85vw;
  }
`;

export const StyledChatWidget2Title = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 24px 24px 0 0;
  transition: all 0.3s;
  display: flex;
  align-items: flex-start;
  justify-content: ${({ $isBrandShown }) =>
    $isBrandShown ? 'space-between' : 'flex-end'};
  padding: 8px;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  transition: all 0.3s;
  padding: 4px 8px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  @media (max-width: 950px) {
    height: 20px;
  }
`;

export const CloseIconContainer = styled.div`
  height: 24px;
  border-radius: 50%;
  transition: all 0.3s;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  cursor: pointer;
  &:hover {
    background: rgba(80, 80, 80, 0.1);
  }
  svg {
    width: 24px;
    height: 24px;
    fill: white;
  }
`;

export const StyledChatWidget2MessagingBlock = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 8px;
`;

export const StyledChatWidget2ScrollingBlock = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  min-height: 100%;
  &::-webkit-scrollbar-track {
    border-radius: 16px;
    background-color: ${({ $primaryColor }) =>
      $primaryColor ? $primaryColor + '15' : '#00b1e515'};
    opacity: 0.1;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: ${({ $primaryColor }) =>
      $primaryColor ? $primaryColor + '15' : '#00b1e515'};
    opacity: 0.1;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: ${({ $primaryColor }) =>
      $primaryColor ? $primaryColor : '#00b1e5'};
  }
`;

export const StyledIsTypingBlock = styled.div`
  font-family: 'Roboto', sans-serif;
  color: white;
  position: fixed;
  bottom: 56px;
  left: 20px;
  font-size: 10px;
  display: block;
  line-height: 10px;
  @media (max-width: 950px) {
    left: 36px;
  }
`;

export const StyledIsTypingDots = styled.span`
  position: relative;
  left: 6px;
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: white;
  animation: ${typingMessage} 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 50%;
  &::after,
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    animation: ${typingMessage} 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  &::before {
    left: 8px;
    animation-delay: 0.5s;
  }
  &::after {
    left: 16px;
    animation-delay: 1s;
  }
`;
