import React, { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { StyledChatWidgetWrapper } from './ChatWidgetStyle';
import ChatWidgetContext from '../../../Contexts/ChatWidgetContext';

export const ChatWidgetVersion1 = () => {
  const {
    isChatWindowOpen,
    handleOpenChatWindow,
    handleCloseChatWindow,
    widgetConfigurationData,
  } = useContext(ChatWidgetContext);

  const handleClickOnWidget = () => {
    isChatWindowOpen ? handleCloseChatWindow() : handleOpenChatWindow();
  };

  return (
    <StyledChatWidgetWrapper
      onClick={handleClickOnWidget}
      $isCloseButton={isChatWindowOpen}
      $primaryColor={widgetConfigurationData.style.primaryColor}
    >
      {isChatWindowOpen ? <CloseIcon /> : <QuestionAnswerIcon />}
    </StyledChatWidgetWrapper>
  );
};
