import React from 'react';
import { StyledSpinnerContainer } from './LoadingSpinner';

export const LoadingSpinner = ({spinnerColor}) => {
  return (
    <StyledSpinnerContainer $spinnerColor={spinnerColor}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledSpinnerContainer>
  );
};
