import styled, { css, keyframes } from 'styled-components';

const displayNone = keyframes`
    0% {
      height: 500px;
    }
    100% {
      height: 0px;
    }
  `;

export const StyledLogoContainer = styled.div`
  z-index: 10002;
  position: absolute;
  min-height: 24px;
  bottom: 10px;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  gap: 4px;
  pointer-events: none;
  @media (max-width: 950px) {
    font-size: 14px;
  }
  ${({ $isChatWindowOpen }) =>
    $isChatWindowOpen &&
    css`
      visibility: hidden;
    `}
`;

export const StyledPlaceholder = styled.span`
  font-family: 'Roboto', sans-serif;
  color: grey;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
`;

export const StyledChatWidget2Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  position: fixed;
  bottom: 12px;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, 0%);
  transition: all 0.3s;
  ${({ $isChatWindowOpen }) =>
    !$isChatWindowOpen &&
    css`
      animation: ${displayNone} 0.8s;
      height: 0px;
    `}
`;

export const StyledChatWidget2InputWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  width: 28vw;
  bottom: 8px;
  left: 50%;
  height: 44px;
  transform: translate(-50%, 0%);
  transition: all 0.3s;
  @media (max-width: 950px) {
    width: 65vw;
  }
  ${({ $isChatWindowOpen }) =>
    $isChatWindowOpen &&
    css`
      width: 44vw;
      @media (max-width: 950px) {
        width: 75vw;
      }
    `}
`;

export const StyledChatWidget2Input = styled.textarea`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;
  line-height: 16px;
  transition: all 0.3s;
  height: 44px !important;
  overflow-wrap: break-word;
  width: 100%;
  padding: 4px 80px 4px 16px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #00000040;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  cursor: ${({ $isChatWindowOpen }) =>
    $isChatWindowOpen ? 'text' : 'pointer'};
  -webkit-backdrop-filter: blur(20px);
  &:hover {
    border-color: #00000060;
  }
  &:focus {
    border-color: #00000090;
  }
`;

export const StyledChatWidget2SendButtonWrapper = styled.div`
  height: 44px;
  z-index: 10002;
  position: absolute;
  bottom: 8px;
  right: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  @media (max-width: 950px) {
    right: 48px;
  }
  @media (max-width: 690px) {
    right: 32px;
  }
  @media (max-width: 490px) {
    right: 24px;
  }
`;

export const StyledSpan = styled.span``;

export const StyledChatWidget2SendButton = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  line-height: 1;
  padding: 6px 14px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  color: ${({ $primaryColor }) => ($primaryColor ? $primaryColor : '#00b1e5')};
  border-radius: 16px;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: ${({ $primaryColor }) =>
      $primaryColor ? $primaryColor : '#00b1e5'};
  }
  &:active {
    color: #ffffff90;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    background-color: #00000050;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: gray;
      pointer-events: none;
    `}
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      & svg {
        margin-left: 0px;
      }
    `}

   @media (max-width: 950px) {
    &:hover {
      color: ${({ $primaryColor }) =>
        $primaryColor ? $primaryColor : '#00b1e5'};
      background-color: transparent;
    }
  }
`;
