import styled, { css, keyframes } from 'styled-components';
import { hexColorDarkGenerator } from '../../../utils';

const iconAnimation = keyframes`
    0% {
     scale:1
    }
    100% {
    scale: 0;
    }
  `;

const typingMessage = keyframes`
  0% {
    background-color: #606060;
  }
  50% {
    background-color: #fff;
  }
  60% {
    background-color: #606060;
  }
  `;

const fadeInUp = keyframes`
from {
  transform: translate3d(0,505px,0)
}
to{
  transform: translate3d(0,0,0);
     opacity: 1
}
`;

const fadeInDown = keyframes`
from {
  transform: translate3d(0,0,0);
     opacity: 1
}
to{
  transform: translate3d(0,545px,0)
}
`;

export const StyledChatWidgetWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 300px;
  min-height: 490px;
  border-radius: 12px;
  position: fixed;
  bottom: 42px;
  right: 42px;
  visibility: hidden;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(6.8px);
  border: 1px solid #ffffff10;
  -webkit-backdrop-filter: blur(6.8px);
  ${({ $isChatWindowOpen }) =>
    $isChatWindowOpen
      ? css`
  visibility: visible;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both
  opacity: 0
  animation-name: ${fadeInUp};
  -webkit-animation-name: ${fadeInUp};
    `
      : css`
  visibility: ${({ $isFirstRender }) =>
    $isFirstRender ? 'hidden' : 'visible'};
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both
  opacity: 0
  animation-name: ${fadeInDown};
  -webkit-animation-name: ${fadeInDown};
    `}
`;

export const StyledChatWidgetTitle = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  font-size: 18px;
  font-weight: 400;
  ${({ $primaryColor }) =>
    $primaryColor &&
    css`
      background: linear-gradient(
        45deg,
        ${$primaryColor + '20'} 0%,
        ${$primaryColor + '20'} 100%
      );
    `}
  backdrop-filter: blur(10.8px);
  -webkit-backdrop-filter: blur(10.8px);
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  transition: all 0.3s;
  padding: 4px 6px;
  img {
    height: 26px;
  }
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  @media (max-width: 950px) {
    height: 20px;
    img {
      height: 16px;
    }
  }
`;

export const StyledChatWidgetMessagingBlock = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 385px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 6px 16px 6px;
  border-radius: 8px;
`;

export const StyledChatWidgetScrollingBlock = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  &::-webkit-scrollbar-track {
    border-radius: 16px;
    background-color: ${({ $primaryColor }) =>
      $primaryColor ? $primaryColor + '15' : '#00b1e515'};
    opacity: 0.1;
  }
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: ${({ $primaryColor }) =>
      $primaryColor ? $primaryColor + '15' : '#00b1e515'};
    opacity: 0.1;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: ${({ $primaryColor }) =>
      $primaryColor ? $primaryColor : '#00b1e5'};
  }
`;

export const StyledChatWidgetInputBlock = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 60px;
  border-radius: 0 0 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 8px;
  ${({ $primaryColor }) =>
    $primaryColor &&
    css`
      background: linear-gradient(
        45deg,
        ${$primaryColor + '20'} 0%,
        ${$primaryColor + '20'} 100%
      );
    `}
  backdrop-filter: blur(10.8px);
  -webkit-backdrop-filter: blur(10.8px);
`;

export const StyledChatWidgetInput = styled.textarea`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  border: none;
  outline: none;
  line-height: 16px;
  transition: all 0.3s;
  resize: none;
  overflow: hidden;
  min-height: 20px;
  max-height: 160px;
  overflow-wrap: break-word;
  width: 225px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #00000010;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10.8px);
  -webkit-backdrop-filter: blur(10.8px);
  &:hover {
    border-color: #00000070;
  }
  &:focus {
    border-color: #000000;
  }
`;

export const StyledChatWidgetSendButton = styled.div`
  box-sizing: border-box;
  border: none;
  outline: none;
  background-image: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  color: white;
  background-color: ${({ $primaryColor }) =>
    $primaryColor ? $primaryColor : '#00B1E5'};
  border: 1px solid #00000050;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  & svg {
    margin-left: 6px;
    transition: all 0.3s;
    width: 26px;
    height: 26px;
    fill: white;
  }
  &:hover {
    background: ${({ $primaryColor }) =>
      $primaryColor ? hexColorDarkGenerator($primaryColor) : '#004265'};
  }
  &:hover svg {
    scale: 1.2;
  }
  &:active {
    scale: 0.9;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  }
  &:active svg {
    animation: ${iconAnimation} 0.2s;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #00000070;
      pointer-events: none;
      border: 1px solid #00000020;
      & svg {
        color: #ffffff90;
      }
    `}
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      & svg {
        margin-left: 0px;
        color: #ffffff;
      }
    `}
`;

export const StyledIsTypingBlock = styled.div`
  font-family: 'Roboto', sans-serif;
  color: white;
  position: fixed;
  bottom: 63px;
  left: 6px;
  font-size: 10px;
  display: block;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  line-height: 10px;
`;

export const StyledIsTypingDots = styled.span`
  position: relative;
  left: 6px;
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: white;
  animation: ${typingMessage} 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 50%;
  &::after,
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    animation: ${typingMessage} 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  &::before {
    left: 8px;
    animation-delay: 0.5s;
  }
  &::after {
    left: 16px;
    animation-delay: 1s;
  }
`;
