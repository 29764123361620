import {
  StyledAIMessageItem,
  StyledAITypingMessage,
  StyledUserMessageItem,
  StyledAiMessageSource,
  StyledMessageItemBlock,
  StyledUserMessageSource,
  StyledMessage,
} from './MessageItemStyles';
import React, { useContext } from 'react';
import NearMeIcon from '@mui/icons-material/NearMe';
import ChatWidgetContext from '../../../Contexts/ChatWidgetContext';

export const MessageItemVersion2 = ({ messageItem }) => {
  const { handleSendMessage, widgetConfigurationData } =
    useContext(ChatWidgetContext);

  const handleClickOnPredefinedQuestion = (event) => {
    if (!messageItem.isPredefinedQuestion) {
      return;
    }
    event.stopPropagation();
    handleSendMessage(messageItem.message);
  };

  return (
    <StyledMessageItemBlock $isUserMessage={messageItem.source === 'user'}>
      {messageItem.source === 'user' ? (
        messageItem.isPredefinedQuestion ? (
          <StyledUserMessageItem
            $isPredefinedQuestion={messageItem.isPredefinedQuestion}
            onClick={handleClickOnPredefinedQuestion}
            $primaryColor={widgetConfigurationData.style.primaryColor}
          >
            {messageItem.message}
            {messageItem.isPredefinedQuestion && <NearMeIcon />}
          </StyledUserMessageItem>
        ) : (
          <StyledUserMessageItem
            $primaryColor={widgetConfigurationData.style.primaryColor}
          >
            <StyledUserMessageSource>
              {widgetConfigurationData.visitorName}
            </StyledUserMessageSource>
            <StyledMessage>{messageItem.message}</StyledMessage>
          </StyledUserMessageItem>
        )
      ) : (
        <StyledAIMessageItem>
          <StyledAiMessageSource>
            {widgetConfigurationData.widgetName}
          </StyledAiMessageSource>
          <StyledAITypingMessage style={{ '--n': '10000' }}>
            {messageItem.message}
          </StyledAITypingMessage>
        </StyledAIMessageItem>
      )}
    </StyledMessageItemBlock>
  );
};
