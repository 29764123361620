import React from 'react';

export const SimulacrumLogoSmall = () => {
  return (
    <svg
      id="Group_4090"
      data-name="Group 4090"
      xmlns="http://www.w3.org/2000/svg"
      width="15.449"
      height="27.16"
      viewBox="0 0 15.449 27.16"
    >
      <path
        id="Path_7327"
        data-name="Path 7327"
        d="M1159.318,1388.487q0,1.584,0,3.168a2.383,2.383,0,0,1-1.212,2.144,3.589,3.589,0,0,1-1.285.557,2.388,2.388,0,0,1-2.318-.8c-.7-.806-1.351-1.652-2.022-2.481q-1.666-2.06-3.33-4.122-2.1-2.594-4.2-5.188a2.519,2.519,0,0,1,1.027-3.914,2.682,2.682,0,0,1,2.378.2c1.609.859,3.231,1.694,4.848,2.54s3.228,1.676,4.829,2.537a2.332,2.332,0,0,1,1.285,2.153c.012,1.068,0,2.136,0,3.2Z"
        transform="translate(-1143.896 -1372.46)"
        fill="#00b1e5"
      />
      <path
        id="Path_7328"
        data-name="Path 7328"
        d="M1146.542,1472.938a2.378,2.378,0,0,1,1.708.935q.634.778,1.263,1.56a2.546,2.546,0,0,1,.2,2.926,2.233,2.233,0,0,1-.8.792c-.631.351-1.269.691-1.906,1.031-.536.286-1.071.577-1.615.848a8.945,8.945,0,0,1-1.426.646,2.357,2.357,0,0,1-2.384-.616,2.471,2.471,0,0,1-.824-1.969,2.248,2.248,0,0,1,.458-1.362c.5-.625,1.008-1.246,1.512-1.868.558-.688,1.107-1.383,1.677-2.062A2.56,2.56,0,0,1,1146.542,1472.938Z"
        transform="translate(-1140.755 -1454.621)"
        fill="#00b1e5"
      />
      <path
        id="Path_7329"
        data-name="Path 7329"
        d="M1201.858,1342.444a2.793,2.793,0,0,1-.8,1.981c-.5.6-.991,1.215-1.482,1.825a2.52,2.52,0,0,1-2.088,1,2.555,2.555,0,0,1-1.172-.329c-.43-.229-.87-.442-1.3-.68a2.518,2.518,0,0,1,.011-4.432c1.067-.57,2.132-1.145,3.215-1.684a2.482,2.482,0,0,1,3.526,1.658C1201.819,1342,1201.83,1342.224,1201.858,1342.444Z"
        transform="translate(-1186.412 -1339.866)"
        fill="#00b1e5"
      />
    </svg>
  );
};
