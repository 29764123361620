import React, { useEffect, useState } from 'react';
import { ChatWidgetContextProvider } from '../../Contexts/ChatWidgetContext';
import { Chat } from './Chat';
import {
  chatHealthCheck,
  getAllowedDomains,
} from '../../service/ChatWidgetService';

export const ChatWidget = ({ widgetKey }) => {
  const [isChatShown, setIsChatShown] = useState(false);

  const checkAllowedDomains = async () => {
    try {
      const { data } = await getAllowedDomains(widgetKey);
      return data.some((domain) => domain.domainName === window.location.host);
    } catch (error) {
      return false;
    }
  };

  const checkChatHealth = async () => {
    try {
      const { data } = await chatHealthCheck(widgetKey);
      return data.hasKnowledgeBase && data.hasToken;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      const result1 = await checkAllowedDomains();
      const result2 = await checkChatHealth();
      setIsChatShown(result1 && result2);
      //Uncomment for development
      // setIsChatShown(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChatWidgetContextProvider>
      {isChatShown ? <Chat widgetKey={widgetKey} /> : null}
    </ChatWidgetContextProvider>
  );
};
