import {
  StyledLogoWrapper,
  StyledIsTypingDots,
  CloseIconContainer,
  StyledIsTypingBlock,
  StyledChatWidget2Title,
  StyledChatWidget2Window,
  StyledChatWidget2ScrollingBlock,
  StyledChatWidget2MessagingBlock,
} from './ChatWindowStyle';
import { SimulacrumLogoWhite } from '../../../assets';
import { MessageItemVersion2 } from '../MessageItemVersion2';
import ChatWidgetContext from '../../../Contexts/ChatWidgetContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ChatWindowVersion2 = () => {
  const {
    chatId,
    isLoading,
    conversation,
    setIsLoading,
    isFirstRender,
    setFirstRender,
    setConversation,
    isChatWindowOpen,
    handleCloseChatWindow,
    widgetConfigurationData,
  } = useContext(ChatWidgetContext);
  const messageListRef = useRef(null);
  const [chatWindowStyle, setChatWindowStyle] = useState({});

  useEffect(() => {
    if (!messageListRef.current) {
      return;
    }
    messageListRef.current.scrollTo({
      top: 20000,
      behavior: 'smooth',
    });
  }, [conversation]);

  useEffect(() => {
    if (!isFirstRender) {
      return;
    }
    setTimeout(() => {
      setFirstRender(false);
      setChatWindowStyle({ visibility: 'visible' });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledChatWidget2Window
      style={chatWindowStyle}
      $isChatWindowOpen={isChatWindowOpen}
      $isFirstRender={isFirstRender}
      $primaryColor={widgetConfigurationData.style.primaryColor}
    >
      <StyledChatWidget2Title
        $isChatWindowOpen={isChatWindowOpen}
        $isBrandShown={widgetConfigurationData.style.isBrandShown}
      >
        {widgetConfigurationData.style.isBrandShown && (
          <StyledLogoWrapper>
            {widgetConfigurationData.style.customLogo ? (
              <img
                src={widgetConfigurationData.style.customLogo}
                alt="icon"
                style={{ height: '24px' }}
              />
            ) : (
              <SimulacrumLogoWhite />
            )}
          </StyledLogoWrapper>
        )}
        <CloseIconContainer onClick={handleCloseChatWindow}>
          <ExpandMoreIcon />
        </CloseIconContainer>
      </StyledChatWidget2Title>
      {!isFirstRender && (
        <StyledChatWidget2MessagingBlock>
          <StyledChatWidget2ScrollingBlock
            ref={messageListRef}
            $primaryColor={widgetConfigurationData.style.primaryColor}
          >
            {conversation.map((messageItem, index) => {
              return (
                <MessageItemVersion2
                  messageItem={messageItem}
                  key={index}
                  setConversation={setConversation}
                  conversation={conversation}
                  setIsLoading={setIsLoading}
                  chatId={chatId}
                />
              );
            })}
            {isLoading && (
              <StyledIsTypingBlock>
                {widgetConfigurationData.widgetName} is typing
                <StyledIsTypingDots></StyledIsTypingDots>
              </StyledIsTypingBlock>
            )}
          </StyledChatWidget2ScrollingBlock>
        </StyledChatWidget2MessagingBlock>
      )}
    </StyledChatWidget2Window>
  );
};
