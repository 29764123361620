import styled, { keyframes, css } from 'styled-components';
import { PulseAnimationGenerator, hexColorDarkGenerator } from '../../../utils';

const messageIconAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const iconAnimation = keyframes`
    0% {
     scale:1
    }
    100% {
    scale: 0.1;
    }
  `;

export const StyledChatWidgetWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  transition: all 0.5s;
  animation: ${({ $primaryColor }) => PulseAnimationGenerator($primaryColor)} 2s
    infinite;
  background: ${({ $primaryColor }) =>
    $primaryColor
      ? `linear-gradient(90deg, ${hexColorDarkGenerator(
          $primaryColor,
        )}, ${$primaryColor} 51%, ${hexColorDarkGenerator(
          $primaryColor,
        )}) var(--x, 0) / 200%;`
      : 'linear-gradient(90deg, #004265, #00b1e5 51%, #004265) var(--x, 0) / 200%;'};
  & svg {
    transition: all 0.5s;
    width: 28px;
    height: 28px;
    fill: white;
  }

  &:hover {
    background-color: ${({ $primaryColor }) => $primaryColor};
    color: #fff;
    scale: 1.1;
    animation: none;
    --x: 100%;
    box-shadow: 0px 0px 7px 0px ${({ $primaryColor }) => $primaryColor};
    -webkit-box-shadow: 0px 0px 7px 0px ${({ $primaryColor }) => $primaryColor};
    -moz-box-shadow: 0px 0px 7px 0px ${({ $primaryColor }) => $primaryColor};
  }
  &:hover svg {
    animation: ${messageIconAnimation} 0.5s;
    transition: all 0.5s;
    scale: 1.1;
  }
  cursor: pointer;

  ${({ $isCloseButton }) =>
    $isCloseButton &&
    css`
      & svg {
        width: 32px;
        height: 32px;
      }
      animation: none;
      &:hover {
        scale: 1.1;
        animation: none;
      }
      &:hover svg {
        animation: none;
        transition: all 0.5s;
        scale: 1.1;
      }
    `}
  &:active {
    scale: 0.9;
  }

  &:active svg {
    animation: ${iconAnimation} 0.2s;
  }
`;
