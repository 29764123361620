import React, { useContext, useEffect, useRef } from 'react';

import {
  StyledPlaceholder,
  StyledLogoContainer,
  StyledChatWidget2Input,
  StyledChatWidget2Wrapper,
  StyledChatWidget2SendButton,
  StyledChatWidget2InputWrapper,
  StyledChatWidget2SendButtonWrapper,
  StyledSpan,
} from './ChatVersion2Styles';
import { ChatWindowVersion2 } from './ChatWindowVersion2';
import ChatWidgetContext from '../../Contexts/ChatWidgetContext';
import { SimulacrumLogoSmall } from '../../assets/SimulacrumLogoSmall';
import { LoadingSpinner } from '../LoadingSpinner';

export const ChatVersion2 = () => {
  const {
    chatId,
    hasToken,
    isLoading,
    userMessage,
    handleKeyDown,
    setUserMessage,
    isChatWindowOpen,
    handleSendMessage,
    handleMessageChange,
    handleOpenChatWindow,
    widgetConfigurationData,
  } = useContext(ChatWidgetContext);
  const inputRef = useRef(null);

  const handleInput = (element) => {
    element.currentTarget.style.height = 'auto';
    element.currentTarget.style.height =
      element.currentTarget.scrollHeight + 'px';
  };

  useEffect(() => {
    if (!isChatWindowOpen || !!chatId) {
      setUserMessage('');
      return;
    }

    inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatWindowOpen]);

  return (
    <StyledChatWidget2Wrapper $isChatWindowOpen={isChatWindowOpen}>
      <StyledChatWidget2InputWrapper $isChatWindowOpen={isChatWindowOpen}>
        <StyledLogoContainer
          $isChatWindowOpen={isChatWindowOpen}
          $isBrandShown={widgetConfigurationData.style.isBrandShown}
        >
          {widgetConfigurationData.style.isBrandShown && (
            <SimulacrumLogoSmall />
          )}
          <StyledPlaceholder>What would you like to know?</StyledPlaceholder>
        </StyledLogoContainer>
        <StyledChatWidget2Input
          onFocus={(e) => {
            isChatWindowOpen
              ? inputRef.current.focus()
              : inputRef.current.blur();
          }}
          $isChatWindowOpen={isChatWindowOpen}
          onClick={handleOpenChatWindow}
          onChange={handleMessageChange}
          value={userMessage}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onInput={handleInput}
        />
      </StyledChatWidget2InputWrapper>
      {isChatWindowOpen && (
        <StyledChatWidget2SendButtonWrapper>
          <StyledChatWidget2SendButton
            onClick={() => {
              handleSendMessage();
            }}
            disabled={isLoading || !userMessage || !hasToken}
            $isLoading={isLoading}
            $primaryColor={widgetConfigurationData.style.primaryColor}
          >
            {isLoading ? (
              <LoadingSpinner
                spinnerColor={widgetConfigurationData.style.primaryColor}
              />
            ) : (
              <StyledSpan>Send</StyledSpan>
            )}
          </StyledChatWidget2SendButton>
        </StyledChatWidget2SendButtonWrapper>
      )}
      <ChatWindowVersion2 />
    </StyledChatWidget2Wrapper>
  );
};
