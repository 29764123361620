import React, { createContext, useEffect, useState } from 'react';
import {
  createChat,
  sendMessage,
  widgetBehaviorWidgetGet,
} from '../service/ChatWidgetService';

const ChatWidgetContext = createContext({});

export const ChatWidgetContextProvider = ({ children }) => {
  const [widgetConfigurationData, setWidgetConfigurationData] = useState(null);
  const [hasChatCreationError, setHasChatCreationError] = useState(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [isPredefinedQuestionAdded, setIsPredefinedQuestionAdded] =
    useState(false);
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [isFirstRender, setFirstRender] = useState(true);
  const [conversation, setConversation] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [widgetKey, setWidgetKey] = useState('');
  const [hasToken, setHasToken] = useState(true);
  const [chatId, setChatId] = useState('');

  const handleSendMessage = async (messageText) => {
    const userMessageText = messageText || userMessage;
    if (!userMessageText.trim() && !isLoading) {
      return;
    }
    const humanMessage = {
      source: 'user',
      message: userMessageText,
    };
    setUserMessage('');
    if (isFirstMessage) {
      setConversation([
        ...conversation.filter((message) => {
          return !message.isPredefinedQuestion;
        }),
        humanMessage,
      ]);
      setIsFirstMessage(false);
    } else {
      setConversation([...conversation, humanMessage]);
    }
    setIsLoading(true);
    try {
      const {
        data: { ai_answer },
      } = await sendMessage({ message: userMessageText, chatId, widgetKey });
      setConversation((prevState) => {
        return [...prevState, { source: 'ai', message: ai_answer }];
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      if (error.response.data.detail.code === 'PERMISSION_DENIED') {
        setHasToken(false);
        setConversation((prevState) => {
          return [
            ...prevState,
            {
              source: 'ai',
              message: `Right now, we are unable to process your inquiry. Please contact us via ${
                widgetConfigurationData
                  ? widgetConfigurationData.behavior.contactInformation
                  : 'email'
              }`,
            },
          ];
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseChatWindow = () => {
    setIsChatWindowOpen(false);
  };

  const handleOpenChatWindow = async () => {
    if (hasChatCreationError) {
      return;
    }
    if (chatId) {
      setIsChatWindowOpen(true);
      return;
    }
    try {
      await handleCreateChat();
      setIsChatWindowOpen(true);
    } catch (error) {
      setHasChatCreationError(true);
    }
  };

  const handleMessageChange = (e) => {
    setUserMessage(e.currentTarget.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.stopPropagation();
      event.preventDefault();
      event.currentTarget.style.height = 'auto';
      if (isLoading || !hasToken) {
        return;
      }
      handleSendMessage();
    }
  };

  const handleCreateChat = async () => {
    const {
      data: { chat_id },
    } = await createChat(widgetKey);
    setChatId(chat_id);
  };

  const getWidgetConfigs = async (widgetKey) => {
    try {
      const { data } = await widgetBehaviorWidgetGet(widgetKey);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!widgetKey) {
      return;
    }
    (async () => {
      try {
        const data = await getWidgetConfigs(widgetKey);
        setWidgetConfigurationData(data);
        setConversation([
          {
            source: 'ai_answer',
            message: data.behavior.introductionText,
            isPredefinedQuestion: false,
          },
          ...conversation,
          ...data.behavior.suggestedMessages
            .filter((item) => {
              return item;
            })
            .map((message) => {
              return {
                source: 'user',
                message: message,
                isPredefinedQuestion: true,
              };
            }),
        ]);
        setIsPredefinedQuestionAdded(true);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetKey]);

  const contextData = {
    handleKeyDown,
    getWidgetConfigs,
    handleCreateChat,
    handleSendMessage,
    handleMessageChange,
    handleOpenChatWindow,
    handleCloseChatWindow,
    isPredefinedQuestionAdded,
    widgetConfigurationData,
    hasChatCreationError,
    isChatWindowOpen,
    isFirstMessage,
    isFirstRender,
    conversation,
    userMessage,
    isLoading,
    hasToken,
    chatId,
    setChatId,
    setWidgetKey,
    setIsLoading,
    setFirstRender,
    setUserMessage,
    setConversation,
    setIsFirstMessage,
    setIsChatWindowOpen,
    setIsPredefinedQuestionAdded,
  };

  return (
    <ChatWidgetContext.Provider value={contextData}>
      {children}
    </ChatWidgetContext.Provider>
  );
};

export default ChatWidgetContext;
