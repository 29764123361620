import React, { useEffect } from 'react';
import { ChatVersion1 } from '../ChatVersion1';
import { ChatVersion2 } from '../ChatVersion2';
import { useContext } from 'react';
import ChatWidgetContext from '../../Contexts/ChatWidgetContext';
import { WidgetVersions } from '../../constants';

export const Chat = ({ widgetKey }) => {
  const { widgetConfigurationData, setWidgetKey } =
    useContext(ChatWidgetContext);

  useEffect(() => {
    setWidgetKey(widgetKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (WidgetVersions[widgetConfigurationData?.widgetType]) {
    case 1:
      return <ChatVersion1 />;
    case 2:
      return <ChatVersion2 />;
    case 3:
      return <div>Version 3</div>;
    default:
      return null;
  }
};
