import React from 'react';
import { ChatWidgetVersion1 } from './ChatWidgetVersion1';
import { ChatWindowVersion1 } from './ChatWindowVersion1';

export const ChatVersion1 = () => {
  return (
    <>
      <ChatWidgetVersion1 />
      <ChatWindowVersion1 />
    </>
  );
};
