import { keyframes } from 'styled-components';

export const hexColorDarkGenerator = (originalColor) => {
  const originalR = parseInt(originalColor.slice(1, 3), 16);
  const originalG = parseInt(originalColor.slice(3, 5), 16);
  const originalB = parseInt(originalColor.slice(5, 7), 16);

  const darkR = Math.round(originalR * 0.5);
  const darkG = Math.round(originalG * 0.5);
  const darkB = Math.round(originalB * 0.5);

  const darkColorHex =
    '#' +
    ((1 << 24) | (darkR << 16) | (darkG << 8) | darkB).toString(16).slice(1);
  return darkColorHex;
};

export const ColorOpacityGenerator = (hexColor, opacity) => {
  return hexColor + opacity;
};

export const PulseAnimationGenerator = (color) => {
  return keyframes`
  0% {
      -moz-box-shadow: 0 0 0 0 ${color};
      box-shadow: 0 0 0 0 ${color};
  }
  70% {
      -moz-box-shadow: 0 0 0 12px ${ColorOpacityGenerator(color, '00')};
      box-shadow: 0 0 0 12px ${ColorOpacityGenerator(color, '00')};
  }
  100% {
      -moz-box-shadow: 0 0 0 0 ${ColorOpacityGenerator(color, '00')};
      box-shadow: 0 0 0 0 ${ColorOpacityGenerator(color, '00')};
  }
`;
};
