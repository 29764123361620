import axios from 'axios';
import { baseURL, baseURLWalle } from '../constants';

export const createChat = async (widgetKey) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${widgetKey}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseURLWalle}/api/v1/ai-chat/create`,
      {},
      axiosConfig,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async ({ chatId, message, widgetKey }) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${widgetKey}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseURLWalle}/api/v1/ai-chat/message`,
      {
        chat_id: chatId,
        message,
      },
      axiosConfig,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const chatHealthCheck = async(widgetKey) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${widgetKey}`,
    },
  };
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get(
      `${baseURLWalle}/api/v1/ai-chat/health-check`,
      axiosConfig,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const widgetBehaviorWidgetGet = async (widgetKey) => {
  try {
    const response = await axios({
      url: `${baseURL}/organization/organizations/widget-configuration`,
      method: 'GET',
      headers: { Authorization: `Bearer ${widgetKey}` },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllowedDomains = async (widgetKey) => {
  try {
    const response = await axios({
      url: `${baseURL}/organization/organizations/domains-by-widget`,
      method: 'GET',
      headers: { Authorization: `Bearer ${widgetKey}` },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
