import {
  StyledLogoWrapper,
  StyledIsTypingDots,
  StyledIsTypingBlock,
  StyledChatWidgetInput,
  StyledChatWidgetTitle,
  StyledChatWidgetWrapper,
  StyledChatWidgetInputBlock,
  StyledChatWidgetSendButton,
  StyledChatWidgetMessagingBlock,
  StyledChatWidgetScrollingBlock,
} from './ChatWindowStyle';
import { SimulacrumLogoWhite } from '../../../assets';
import { MessageItemVersion1 } from '../MessageItemVersion1';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ChatWidgetContext from '../../../Contexts/ChatWidgetContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LoadingSpinner } from '../../LoadingSpinner';

export const ChatWindowVersion1 = () => {
  const [chatWindowStyle, setChatWindowStyle] = useState({});
  const messageListRef = useRef(null);
  const inputRef = useRef(null);

  const {
    chatId,
    hasToken,
    isLoading,
    userMessage,
    setIsLoading,
    conversation,
    isFirstRender,
    handleKeyDown,
    setFirstRender,
    setConversation,
    isChatWindowOpen,
    handleSendMessage,
    handleMessageChange,
    widgetConfigurationData,
  } = useContext(ChatWidgetContext);

  const handleInput = (element) => {
    element.currentTarget.style.height = 'auto';
    element.currentTarget.style.height =
      element.currentTarget.scrollHeight + 'px';
  };

  useEffect(() => {
    if (!messageListRef.current) {
      return;
    }
    messageListRef.current.scrollTo({
      top: 20000,
      behavior: 'smooth',
    });
  }, [conversation]);

  useEffect(() => {
    if (!isChatWindowOpen) {
      return;
    }
    inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (!isFirstRender) {
      return;
    }
    setTimeout(() => {
      setFirstRender(false);
      setChatWindowStyle({ visibility: 'visible' });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledChatWidgetWrapper
        style={chatWindowStyle}
        $isFirstRender={isFirstRender}
        $isChatWindowOpen={isChatWindowOpen}
      >
        <StyledChatWidgetTitle
          $primaryColor={widgetConfigurationData.style.primaryColor}
        >
          {widgetConfigurationData.style.isBrandShown && (
            <StyledLogoWrapper>
              {widgetConfigurationData.style.customLogo ? (
                <img
                  src={widgetConfigurationData.style.customLogo}
                  alt="Icone adicionar"
                />
              ) : (
                <SimulacrumLogoWhite />
              )}
            </StyledLogoWrapper>
          )}
        </StyledChatWidgetTitle>
        <StyledChatWidgetMessagingBlock>
          <StyledChatWidgetScrollingBlock
            ref={messageListRef}
            $primaryColor={widgetConfigurationData.style.primaryColor}
          >
            {conversation.map((messageItem, index) => {
              return (
                <MessageItemVersion1
                  key={index}
                  chatId={chatId}
                  messageItem={messageItem}
                  conversation={conversation}
                  setIsLoading={setIsLoading}
                  setConversation={setConversation}
                />
              );
            })}
            {isLoading && (
              <StyledIsTypingBlock>
                {widgetConfigurationData.widgetName} is typing
                <StyledIsTypingDots></StyledIsTypingDots>
              </StyledIsTypingBlock>
            )}
          </StyledChatWidgetScrollingBlock>
        </StyledChatWidgetMessagingBlock>
        <StyledChatWidgetInputBlock
          $primaryColor={widgetConfigurationData.style.primaryColor}
        >
          <StyledChatWidgetInput
            onChange={handleMessageChange}
            value={userMessage}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            onInput={handleInput}
          />
          <StyledChatWidgetSendButton
            onClick={() => {
              handleSendMessage();
            }}
            disabled={isLoading || !userMessage || !hasToken}
            $isLoading={isLoading}
            $primaryColor={widgetConfigurationData.style.primaryColor}
          >
            {isLoading ? <LoadingSpinner /> : <SendRoundedIcon />}
          </StyledChatWidgetSendButton>
        </StyledChatWidgetInputBlock>
      </StyledChatWidgetWrapper>
    </>
  );
};
